import React, { useEffect, useState } from 'react';
import LoadingIcon from '@element-plus/icons-svg/loading.svg';
import '../../../style/components/modal/loadingModal.less';
import { Modal } from './Modal.jsx';
import { eventBus } from '../../lib/eventBus.jsx';
import { sleep } from '../../../../common/helper/util.mjs';

export async function toggleLoadingModal() {
    await sleep(1);
    eventBus.emit('toggleLoadingModal');
}

export function LoadingModal() {
    const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
    useEffect(() => {
        eventBus.on('toggleLoadingModal', () => {
            setIsLoadingModalOpen(prev => !prev);
        });
        return () => {
            eventBus.removeEvent('toggleLoadingModal');
        };
    }, []);
    return (
        <Modal className="loading-modal" isOpen={isLoadingModalOpen}>
            <div className="loading-wrap">
                <LoadingIcon className="loading-icon spin" />
                <div className="text">请稍等...</div>
            </div>
        </Modal>
    );
}
