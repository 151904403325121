export function getSessionId() {
    return window.localStorage.getItem("sessionId");
}

export function setSessionId(sessionId) {
    window.localStorage.setItem("sessionId", sessionId);
}
export function removeSessionId() {
    window.localStorage.removeItem("sessionId");
}
