import React, { useEffect } from 'react';
import { Nav } from '../nav/Nav';
import '../../../style/components/layout.less';
import '../../../style/common/global.less';
import { ConfirmModal } from '../modal/ConfirmModal.jsx';
import { LoadingModal, toggleLoadingModal } from '../modal/LoadingModal.jsx';
import { userStore } from '../../store/UserStore.jsx';
import { confirmModalStore } from '../../store/ConfirmModal.jsx';

export function Main(props) {
    const [isInit, setIsInit] = React.useState(true);
    useEffect(() => {
        // 定义一个异步函数并在内部调用
        async function initUser() {
            toggleLoadingModal();
            await userStore.initPromise;
            setIsInit(false);
            toggleLoadingModal();
        }

        // 调用这个异步函数
        initUser();
    }, []);
    return (
        <>
            <Nav />
            <div className="content">{isInit ? null : props.content}</div>
            <div className="footer">
                <div className="censor">
                    <a type="link" target="_blank" href="https://beian.miit.gov.cn">
                        粤ICP备13049010号-3
                    </a>
                    <span>版权所有 东莞市大岭山莱咖软件开发中心</span>
                </div>
            </div>
            <ConfirmModal store={confirmModalStore} />
            <LoadingModal />
        </>
    );
}
