import React from 'react';
import '../../../style/components/nav.less';
import UserIcon from '@element-plus/icons-svg/user.svg';
import Menu from '../common/Menu.jsx';
import { userStore } from '../../store/UserStore.jsx';
import { useState } from 'react';

export function Nav() {
    const [leftMenu] = useState([
        {
            title: 'LazyCoffee',
            href: '/',
        },
    ]);
    let rightMenu = [];
    const aboutUsMenu = {
        title: '关于我们',
        href: '/static/about-us',
    };
    if (userStore.isLogin) {
        rightMenu = [
            aboutUsMenu,
            {
                title: userStore.nickname,
                icon: <UserIcon />,
                submenu: [
                    {
                        title: '新建文章',
                        href: '/article/upsert',
                    },
                    {
                        title: '我的文章',
                        href: '/user/my-articles',
                    },
                    {
                        title: '个人资料',
                        href: '/user/setting',
                    },
                    {
                        title: '退出登录',
                        method: 'post',
                        url: '/api/user/logout',
                        href: '/',
                    },
                ],
            },
        ];
    } else {
        rightMenu = [
            aboutUsMenu,
            {
                title: '登录',
                href: '/user/login',
            },
            {
                title: '注册',
                href: '/user/register',
            },
        ];
    }
    const leftMenuCp = leftMenu.map(menu => <Menu {...menu} key={menu.title} />);
    const rightMenuCp = rightMenu.map(menu => <Menu {...menu} key={menu.title} />);
    return (
        <div className="nav-menu">
            <div className="left">{leftMenuCp}</div>
            <div className="right">{rightMenuCp}</div>
        </div>
    );
}
