import Button from '../common/Button.jsx';
import ModalContent from './ModalContent.jsx';
import React from 'react';
import { observer } from 'mobx-react-lite';

function ConfirmModalComponent({ store }) {
    const isOpen = store.isOpenConfirmModal;
    const footer = (
        <div className="buttons">
            <Button onClick={store.onClose.bind(store)}>取消</Button>
            <Button className="primary" onClick={store.onSubmit.bind(store)}>
                确认
            </Button>
        </div>
    );
    return (
        <ModalContent
            className="confirm-modal"
            isOpen={isOpen}
            onClose={store.onClose.bind(store)}
            footer={footer}
            title={store.title}
        >
            {store.content}
        </ModalContent>
    );
}

export const ConfirmModal = observer(ConfirmModalComponent);
