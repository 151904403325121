import { Modal } from './Modal.jsx';
import Button from '../common/Button.jsx';
import React from 'react';

export default class ModalContent extends React.Component {
    constructor(props) {
        super();
        let isShowCloseButton = false;
        if (props.onClose) {
            isShowCloseButton = true;
        }
        let isShowSubmitButton = false;
        if (props.onSubmit) {
            isShowSubmitButton = true;
        }

        this.state = {
            isShowCloseButton,
            isShowSubmitButton,
        };
    }
    render() {
        let footer = [];
        if (this.state.isShowCloseButton) {
            footer.push(<Button onClick={this.props.onClose.bind(this)}>关闭</Button>);
        }
        if (this.state.isShowSubmitButton) {
            footer.push(<Button onClick={this.props.onSubmit.bind(this)}>提交</Button>);
        }
        const modalContentStyle = {
            width: this.props.width,
            height: this.props.height,
            minWidth: this.props.minWidth || '300px',
            minHeight: this.props.minHeight || '300px',
            maxWidth: this.props.maxWidth,
            maxHeight: this.props.maxHeight,
        };
        return (
            <Modal isOpen={this.props.isOpen}>
                <div className="modal-content" style={modalContentStyle}>
                    <div className="modal-title">{this.props.title || 'Modal'}</div>
                    <div className="modal-body">{this.props.children}</div>
                    <div className="modal-footer">{this.props.footer || footer}</div>
                </div>
            </Modal>
        );
    }
}
