import React from "react";
import { mergeClassNames } from "../../lib/dom.jsx";
import { toast } from "../../lib/toast.jsx";
import { post } from "../../lib/request.jsx";
import { toggleLoadingModal } from "../modal/LoadingModal.jsx";
import "../../../style/components/common/Menu.less";
import { useState, useEffect } from 'react';

const Menu = (props) => {
  const ajax = async (menuInfo) => {
    toggleLoadingModal();
    const res = await post({ url: menuInfo.url });
    toggleLoadingModal();
    const result = res.result;
    if (result.code === 200) {
      toast(result.message || '执行成功');
      window.location.href = menuInfo.href;
      return;
    }
    toast(result.message || '执行失败');
  };
  const clickMenu = (menuInfo) => {
    const { submenu = [] } = menuInfo;
    if (submenu.length) return;
    console.log('menu info: ', menuInfo);
    const { href, method } = menuInfo;
    if (!href) {
      toast('未配置菜单链接');
      throw new Error('href is not defined in menu info: ', menuInfo);
    }
    if (method === 'post') {
      ajax(menuInfo);
      return;
    }
    window.location.href = href;
  };
  const { className, icon, title, submenu = [] } = props;
  const thisClassName = ['menu'];
  const submenuCpList = submenu.map((menuInfo) => (
    <Menu {...menuInfo} key={menuInfo.href} />
  ));
  const submenuWrapCp = submenu.length ? (
    <div className="submenu">{submenuCpList}</div>
  ) : null;
  if (submenuWrapCp) {
    thisClassName.push('has-submenu');
  }
  const classNames = mergeClassNames(thisClassName, className);
  return (
    <div
      className={classNames}
      onClick={() => clickMenu(props)}
    >
      {icon && <div className="icon">{icon}</div>}
      <div className="title">{title}</div>
      {submenuWrapCp}
    </div>
  );
};

export default Menu;
