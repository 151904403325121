import React from 'react';
import '../../../style/components/common/Button.less';
import { isDisabled, mergeClassNames } from '../../lib/dom.jsx';
import LoadingSvg from '@element-plus/icons-svg/loading.svg';

export default function Button(props) {
    let disabled = isDisabled(props.disabled);
    let loading;
    if (props.isLoading) {
        disabled = true;
        loading = <LoadingSvg className="loading-icon spin" />;
    }
    const buttonClass = mergeClassNames(['button'], props.className, {
        disabled: disabled,
        primary: props.type === 'primary',
        loading: props.isLoading,
    });
    let onClick;
    if (props.onClick) {
        onClick = props.onClick;
    } else if (props.href) {
        onClick = () => {
            window.location.href = props.href;
        };
    } else {
        onClick = () => {};
    }
    return (
        <div className={buttonClass} onClick={onClick}>
            {props.children}
            {loading}
        </div>
    );
}
