import { getSessionId, setSessionId } from "./session";
export async function post({ url, headers, body, bodyType }) {
    if (!url) {
        throw new Error("url is required");
    }
    if (!body) {
        body = {};
    }
    // headers
    let hs;
    if (headers instanceof Headers) {
        hs = headers;
    } else {
        hs = new Headers();
        if (headers) {
            for (const key in headers) {
                if (Object.hasOwnProperty.call(headers, key)) {
                    const value = headers[key];
                    hs.append(key, value);
                }
            }
        }
    }
    const sessionId = getSessionId();
    if (sessionId) {
        hs.append("x-session-id", sessionId);
    }
    // body
    if (body instanceof FormData) {
        bodyType = "form";
    }
    bodyType = bodyType || "json";
    if (bodyType === "json") {
        hs.append("Content-Type", "application/json");
        if (typeof body === "object") {
            body = JSON.stringify(body);
        }
    } else if (bodyType === "form") {
        if (!(body instanceof FormData)) {
            if (typeof body === "object") {
                const formData = new FormData();
                Object.keys(body).forEach((key) => {
                    const value = body[key];
                    if (typeof value === "object") {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                });
                body = formData;
            }
        }
    }
    // send
    let response;
    try {
        response = await fetch(url, {
            method: "POST",
            headers: hs,
            body,
        });
    } catch (error) {
        console.error("Error fetching data:", error);
        return {
            error: "Failed to fetch data",
            code: 500,
        };
    }

    const responseHeaders = response.headers;
    const responseSessionId = responseHeaders.get("x-session-id");
    if (responseSessionId) {
        setSessionId(responseSessionId);
    }
    return await response.json();
}
